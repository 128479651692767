<template>
  <div id="PaymentTrue" />
</template>

<script>
import api from '@/api'

export default {
  name: 'PaymentTrue',

  computed: {
    paymentStatus() {
      return this.$store.getters.paymentStatus
    }
  },

  async created() {
    //https://www.gong-online.co.il/payment-true?p1=0&status=0&p5=XXXX-5911&card_number=XXXX-5911&p24=0&brand=0&p25=2&defrayed=2&p29=4&param_j=4&p30=0526&card_expiration_date=0526&p34=0&card_company_res_id=0&p35=1&card_company_res_cvv=1&p36=7500&amount=75&p60=6&card_company_code=6&p61=01&transaction_type=01&p63=1&credit_terms=1&p65=ILS&currency_iso=ILS&p70=2&source_approval=2&p71=%20037140&auth_number=%20037140&p78=75&payment_first_amount=75&p86=0&payment_non_first_amount=0&p94=1&num_of_payments=1&p96=19&file_number=19&p104=%20%20%20%20%20%20%20MAX%20VISA&card_name=%20%20%20%20%20%20%20MAX%20VISA&p119=0&card_out_of_country=0&p120=6549&user_data=6549&key=f2c77c16-786c-4f5c-8f68-6d8cc4225da7&token=f2c77c16-786c-4f5c-8f68-6d8cc4225da7&hkeva=0&hk1=0&hk2=0&p200=066528159&card_holder_id=066528159&p201=%D7%90%D7%9C%D7%99%D7%97%D7%99%20%D7%9E%D7%99%D7%A1%D7%9F%20&card_holder_name=%D7%90%D7%9C%D7%99%D7%97%D7%99%20%D7%9E%D7%99%D7%A1%D7%9F%20&p205=ILS&p210=%20&full_name=%20&p211=066528159&p212=&phone=&p213=&email=&p214=&address=&p215=&city=&p216=&country=&p217=&zip_code=&p218=

    const resp = await api.payment.verify(this.$route.query)
    if (resp.status) {
      this.$store.commit('setSaveCheckoutStatus', {
        status: false,
        orderId: this.$route.query.more_info,
        payplusIframe: '',
        paypal: ''
      })
      this.$store.commit('clearCart')
      this.$router.push({name: 'ThankYou'})
    }
  }
}
</script>
